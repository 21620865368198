import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { exportTickets } from '~services/item';
import { formatterDate } from '~utils/formatter';
import { downloadFileByRequest } from '~utils/download';

interface HandleExportTicketsParams {
  trackingCodes?: string[];
  itemIds?: string[];
  routeId?: string;
}

interface ExportTicketsReturn {
  loading: boolean;
  onExportTickets: (params: HandleExportTicketsParams) => Promise<void>;
}

export const useExportTickets = (): ExportTicketsReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingExportTickets, , executeExportTickets] = useLazyRequest({
    request: exportTickets,
    withPostSuccess: (response) => {
      if (response.headers && response.data) {
        const currentDate = formatterDate(new Date(), { format: 'YYYYMMDD' });

        const defaultFileName = `etiquetas-${currentDate}.pdf`;

        downloadFileByRequest(response.headers, response.data, defaultFileName);

        enqueueSnackbar(`Se han descargado las etiquetas`, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          'No se pudo descargar las etiquetas, intente nuevamente',
          { variant: 'error' },
        );
      }
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo descargar las etiquetas, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const onExportTickets = useCallback(
    async ({ trackingCodes, routeId, itemIds }: HandleExportTicketsParams) => {
      await executeExportTickets({
        trackingCodes: trackingCodes ?? [],
        itemIds: itemIds ?? [],
        routeId: routeId ?? null,
      });
    },
    [executeExportTickets],
  );

  return { loading: loadingExportTickets, onExportTickets };
};
